import React from "react";
import {
  Container,
  Wrapper,
  CopyRight,
  FlexBox,
  Description,
  ItemHolder,
  Title,
  StyledLink,
  AncerLink,
  LogoHolder,
  ShortText,
  PDFAssociationHolder,
  UlFooter,
  ListFooter,
  RightItemHolder,
} from "./FooterStyled";
import { ReactComponent as PDFLogo } from "../Icons/LogoFooter.svg";
import { ReactComponent as Association } from "../Icons/Association.svg";
import { ReactComponent as Payment } from "../Icons/paymentMethods.svg";

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <FlexBox>
          <ItemHolder>
            <Title> PDF Pro Software Inc.</Title>
            <Description>
              1383 W. 8th Avenue
              <br /> Vancouver, B.C.
              <br /> V6H 3W4, Canada
            </Description>
            <AncerLink underline href="mailto: hello@pdfpro.com">
              hello@pdfpro.com
            </AncerLink>
            <LogoHolder>
              <PDFLogo />
            </LogoHolder>
          </ItemHolder>
          <RightItemHolder>
            <ItemHolder>
              <Title>Products</Title>
              <StyledLink to="/">PDF Pro</StyledLink>
              <StyledLink to="/pdf-online-tools/">Online Tools</StyledLink>
              <UlFooter>
                <ListFooter>
                  <StyledLink to="/merge-pdf/">Merge PDF</StyledLink>
                </ListFooter>
                <ListFooter>
                  <StyledLink to="/rotate-pdf/">Rotate PDF</StyledLink>
                </ListFooter>
                <ListFooter>
                  <StyledLink to="/pdf-to-png/">PDF to PNG</StyledLink>
                </ListFooter>
                <ListFooter>
                  <StyledLink to="/pdf-to-jpg/">PDF to JPG</StyledLink>
                </ListFooter>
              </UlFooter>
              <StyledLink underline to="/pdf-online-tools/">
                All Online Tools
              </StyledLink>
            </ItemHolder>
            <ItemHolder>
              <Title>Payment</Title>
              <Payment />
            </ItemHolder>

            <ItemHolder>
              <Title>Support</Title>
              <AncerLink
                href="https://support.pdfpro.com/hc/en-us/categories/900000414286-Knowledge-Base"
                target="_blank"
                rel="noopener noreferrer"
              >
                Knowledge Base
              </AncerLink>
              <AncerLink
                href="https://support.pdfpro.com/hc/en-us/requests/new"
                target="_blank"
                rel="noopener noreferrer"
              >
                Submit a Ticket
              </AncerLink>
              <AncerLink
                href="https://support.pdfpro.com/hc/en-us/sections/4407459740569-FAQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </AncerLink>
              <AncerLink
                href="https://www.pdfpro.com/blog/release-notes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Release Notes
              </AncerLink>
              <AncerLink
                href="https://pdfpro.com/volume-licensing/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Volume Licensing
              </AncerLink>
            </ItemHolder>

            <ItemHolder>
              <Title>Policy</Title>
              <StyledLink to="/licensing">Licence Information</StyledLink>
              <StyledLink to="/DPA">DPA</StyledLink>
              <StyledLink to="/terms">Terms & Conditions</StyledLink>
              <StyledLink to="/cookies">Cookie Policy</StyledLink>
              <StyledLink to="/privacy">Privacy Policy</StyledLink>
              <StyledLink to="/refund-policy">Refund Policy</StyledLink>
            </ItemHolder>
            <ItemHolder>
              <Title>Company</Title>
              <StyledLink to="/about">About Us</StyledLink>
              <StyledLink to="/careers">Careers</StyledLink>
              <StyledLink to="/blog">Blog</StyledLink>
              <AncerLink
                href="https://pdfa.org/member/pdf-pro-software-inc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Member of:
              </AncerLink>
              <AncerLink
                href="https://pdfa.org/member/pdf-pro-software-inc/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="PDFA"
                className="footer-link"
              >
                <PDFAssociationHolder>
                  <Association />
                </PDFAssociationHolder>
              </AncerLink>
            </ItemHolder>
          </RightItemHolder>
        </FlexBox>
        <ShortText>
          PDF Pro is a desktop software for Windows distributed by PDF Pro
          Software Inc. The software "PDF Pro" and the company "PDF Pro Software
          Inc." are in no way affiliated with Adobe. The PDF file format is an
          open file format published under ISO 32000-1:2008
        </ShortText>
        <CopyRight>
          © 2017 - {new Date().getFullYear()}, PDF Pro Software Inc. All rights
          reserved.
        </CopyRight>
      </Container>
    </Wrapper>
  );
};

export default Footer;
